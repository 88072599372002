/**
 * texts
 **/
h1,
h2,
h3,
h4,
h5 {
  color: #006986;
  font-weight: 600;
}

h1 {
  height: 2.1rem;
  line-height: 2.5rem;
  padding-bottom: 24px;
  margin: 0px;
}

h2 {
  margin: 0px;
  padding-bottom: 16px;
}

p {
  color: #006986;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-bottom: auto;
  font-size: 17px;
  line-height: 24px;
}

a {
  color: #006986;
}

hr {
  border-top: 1px solid #c2c2c2;
  border-bottom: 0px;
}

/**
 * My Styles
 **/
body {
  margin: 0;
  font-family: Poppins;
  height: 100%;
  background-color: #f6f6f6;
}

.full-width {
  width: 100%;
}

.line-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4em;
}

.line-wrapper-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.4em;
}

.line-wrapper-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.4em;
}

.input-icon-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.4em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.info-icon {
  color: #c2c2c2;
}

.error-icon {
  color: #b6193d;
}

.check-icon {
  color: #1ab700;
}

.help-icon {
  color: #006986;
  font-size: 20px;
  padding-top: 4px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.justify-content-center {
  justify-content: center;
}

.text-bold {
  font-weight: 600;
}

.alert {
  border: 1px solid #ccc;
  padding: 1em 0.7em;

  &.alert-danger {
    background-color: rgba(255, 162, 162, 0.7);
  }

  &.alert-warning {
    background-color: rgba(246, 255, 162, 0.7);
  }
}

.error-toast {
  background-color: rgba(255, 162, 162, 0.7);
  color: black;
}

.success-toast {
  background-color: rgba(181, 255, 162, 0.7);
  color: black;
}

.info-toast {
  background-color: rgba(255, 253, 162, 0.7);
  color: black;
}

.img-contain {
  border-radius: 0 !important;
  object-fit: contain !important;
}

.text-logo {
  font-weight: 200;
  margin-left: 1.8em;
  border-left: 1px solid #ccc;
  padding-left: 1.8em;
}

small {
  font-size: 0.5em;
}

.logo-nav {
  max-height: 42px;
}

.clickable:hover {
  cursor: pointer;
}

.header {
  display: flex;
  background-color: rgb(255, 255, 255, 0.5);
  border-bottom: 1px solid #c2c2c2;
  box-shadow: unset;
  padding-top: 0.5rem;
  width: 100%;
  z-index: 10;
  justify-content: space-between;
}

.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}

@media (max-width: 1000px) {
  .card-container.full-width td p,
  .card-container.full-width th p {
    font-size: 15px !important;
    word-break: break-word;
  }

  .card-container.full-width th,
  .card-container.full-width td {
    padding-left: 5px !important;
  }

  .card-container.full-width th {
    word-break: initial !important;
  }

  .card-container.full-width th:last-child {
    padding-right: 2px !important;
  }

  .card-container.full-width th p:first-child {
    width: max-content;
  }

  table {
    padding: 16px 1px !important;
  }
}
