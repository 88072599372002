@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'CardFont';
  src: url(../assets/fonts/OCRAStd.otf) format('truetype');
  font-weight: normal;
}
