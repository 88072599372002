@use 'node_modules/bootstrap/scss/bootstrap-grid';
@use './styles/palette';
@use './styles/fonts';
@use './styles/global-non-material.scss';

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: Poppins;
  /*   overflow-x: hidden; */
}

body {
  min-height: 100vh;
  background-color: rgb(255, 255, 255, 0.7);
}

/* app-root {
  max-width: 1200px;
  width: 100%;
} */

// Define the Odfin theme
.optadata-theme {
  .overview-color {
    color: #6e7e8a;
  }

  .overview-header-color {
    background-color: #6e7e8a;
  }

  .loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #6e7e8a;
    border-left: 6px solid #6e7e8a;
    border-bottom: 6px solid #6e7e8a;
  }
}

// Override styles
@media (max-width: 600px) {
  body {
    font-size: 14px;
  }

  .logo-nav {
    max-width: 8rem !important;
    min-width: 100px;
    max-height: 45px;
    padding-top: 8px;
  }
}

table {
  white-space: normal;
  word-break: break-all;

  tr:last-child td {
    border: 0;
  }
}

.box-shadow {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
}

.button-size-big {
  font-size: 18px;
}

@media (max-width: 600px) {
  .button-size-big {
    font-size: 14px;
  }
}

.button-size-small {
  font-size: 14px;
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  max-width: 1400px !important;
  overflow-y: auto;
}
